<script>
import WhatsappWidget from "@/components/whatsapp-widget";
// import VueWhatsappWidget from 'vue-whatsapp-widget'
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
    WhatsappWidget
    // VueWhatsappWidget
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="/images/duppla_light_header.png" height="24" alt="" />
            </a>
            <p class="mt-4">
              Los mejores especialistas de cáncer para la  prevención, diagnóstico oportuno, opinión clínica y navegación. El beneficio que salva vidas.
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item">
                <a href="https://www.facebook.com/duppla.doctor" target="_blank" class="rounded">
                  <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://www.instagram.com/duppla.doctor/" target="_blank" class="rounded">
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>
              <!-- <li class="list-inline-item ml-1">
                <a href="javascript:void(0)" target="_blank" class="rounded">
                  <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                </a>
              </li> -->
                <li class="list-inline-item ml-1">
                <a href="https://www.youtube.com/channel/UCd6EsceWNh58K1aiP0-BFxg" target="_blank" class="rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg>
                </a>
              </li>
              <li class="list-inline-item ml-1">
                <a href="https://www.linkedin.com/company/duppladoctor/" target="_blank" class="rounded">
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->

          <div class="col-lg-3 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Duppla</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <a href="https://www.duppla.doctor/es-mx/home" class="text-foot" target="_blank">
                  <i class="mdi mdi-chevron-right mr-1"></i>Home
                </a>
              </li>
              <li>
                <a href="https://www.duppla.doctor/es-mx/empresas" class="text-foot" target="_blank">
                  <i class="mdi mdi-chevron-right mr-1"></i>Empresas
                </a>
              </li>
              <li>
                <a href="https://www.duppla.doctor/es-mx/individuos" class="text-foot" target="_blank">
                  <i class="mdi mdi-chevron-right mr-1"></i>Individuos
                </a>
              </li>
              <li>
                <a href="https://www.duppla.doctor/es-mx/especialistas" class="text-foot" target="_blank">
                  <i class="mdi mdi-chevron-right mr-1"></i>Especialistas
                </a>
              </li>
              <li>
                <a href="https://blog.duppla.doctor/" class="text-foot" target="_blank">
                  <i class="mdi mdi-chevron-right mr-1"></i> Hub de Información
                </a>
              </li>
            </ul>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Enlaces de interés</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/terminos/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Términos y condiciones</router-link
                >
              </li>
              <li>
                <router-link to="/privacidad" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Políticas de privacidad</router-link
                >
              </li>
              <!--li>
                <router-link to="/documentation" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Documentation</router-link
                >
              </li>
              <li>
                <router-link to="/changelog" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Changelog</router-link
                >
              </li>
              <li>
                <router-link to="/components" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i>
                  Components</router-link
                >
              </li-->
            </ul>
          </div>
          <!--end col-->

          <!--div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <h5 class="text-light footer-head">Newsletter</h5>
            <p class="mt-4">Sign up and receive the latest tips via email.</p>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="foot-subscribe form-group">
                    <label
                      >Write your email
                      <span class="text-danger">*</span></label
                    >
                    <div class="position-relative">
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control pl-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <input
                    type="submit"
                    id="submitsubscribe"
                    name="send"
                    class="btn btn-soft-primary btn-block"
                    value="Subscribe"
                  />
                </div>
              </div>
            </form>
          </div-->
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2020 - {{ new Date().getFullYear() }} Hub de Pacientes DPPL, S.A.P.I. de C.V. Diseñado con 
                <i class="mdi mdi-heart text-danger"></i>
              </p>
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0 mb-5">
            <ul class="list-unstyled text-sm-right mb-0">
              <li class="list-inline-item">
                <img
                  src="/images/payments/american-ex.png"
                  class="avatar avatar-ex-sm"
                  title="American Express"
                  alt=""
                />
              </li>
              <!--li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/discover.png"
                    class="avatar avatar-ex-sm"
                    title="Discover"
                    alt=""
                /></a>
              </li-->
              <li class="list-inline-item ml-1">
                <img
                  src="/images/payments/master-card.png"
                  class="avatar avatar-ex-sm"
                  title="Master Card"
                  alt=""
                />
              </li>
              <!--li class="list-inline-item ml-1">
                <a href="javascript:void(0)"
                  ><img
                    src="images/payments/paypal.png"
                    class="avatar avatar-ex-sm"
                    title="Paypal"
                    alt=""
                /></a>
              </li-->
              <li class="list-inline-item ml-1">
                <img
                  src="/images/payments/visa.png"
                  class="avatar avatar-ex-sm"
                  title="Visa"
                  alt=""
                />
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <whatsapp-widget source="Main Page Widget"/>
  </div>
</template>
